import React from 'react';
import { motion } from 'framer-motion';


const loaderVariants = {
    'animation': {
        x: [-20, 20],
        y: [0, -30],
        transition: {
            x: {
                yoyo: Infinity,
                duration: 0.5
            },
            y: {
                yoyo: Infinity,
                duration: 0.25,
                ease: 'easeOut'
            }
        }
    }
};

const TheLoader = () => {
    return (
        <div className='h-screen w-full bg-black'>
            <div className='h-full w-full flex items-center justify-center'>
                <motion.div className='w-10 h-10 rounded-full bg-white m-auto'
                    variants={loaderVariants}
                    animate="animation"
                ></motion.div>
            </div>
        </div>
    );
};


export default TheLoader;