import React, { Suspense, useEffect } from 'react';
import TheLoader from './components/layout/TheLoader';
import ReactGA from "react-ga4";

const TheHomepage =  React.lazy(() => import('./TheHomepage'));



const App = () => {

  useEffect(() => {

    ReactGA.initialize("G-5T3P94R0HB");
    ReactGA.send("/");

  }, []);

  return (
    <>
      <Suspense fallback={<TheLoader />} >
        <TheHomepage />
      </Suspense>
    </>
  );
}



export default App;
